import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLuxon from 'vue-luxon'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-pro/css/all.css'

Vue.config.productionTip = false

Vue.use(VueLuxon, {
    input: {
        zone: 'Europe/Amsterdam',
    },
    output: {
        zone: 'Europe/Amsterdam',
        format: "dd-MM-yyyy hh:mm",
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
