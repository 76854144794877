import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "authLogin" */ '../views/auth/AuthLoginView.vue'),
        meta: {
            show_navigation: false,
            show_header: false,
            requires_auth: false,
        },
    },
    {
        path: '/wachtwoord-vergeten',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword.vue'),
        meta: {
            show_navigation: false,
            show_header: false,
            requires_auth: false,
            show_back_button: true,
        },
    },
    {
        path: '/wachtwoord-reset/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
        meta: {
            show_navigation: false,
            show_header: false,
            requires_auth: false,
            show_back_button: true,
        },
    },
    {
        path: '/',
        name: 'DashboardView',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
    },
    {
        path: '/bestellingen',
        name: 'MaterialOrderListView',
        component: () => import(/* webpackChunkName: "materialOrders" */ '../views/MaterialOrderListView.vue')
    },
    {
        path: '/werkbonnen',
        name: 'WorkOrderListView',
        component: () => import(/* webpackChunkName: "workOrders" */ '../views/workOrder/WorkOrderListView.vue')
    },
    {
        path: '/werkbonnen/:id',
        name: 'WorkOrderView',
        component: () => import(/* webpackChunkName: "workOrderDetail" */ '../views/workOrder/WorkOrderDetailView.vue')
    },
    {
        path: '/tijdsregistratie',
        name: 'TimeRegistrationView',
        component: () => import(/* webpackChunkName: "timeRegistration" */ '../views/TimeRegistrationListView.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },

    routes
})


router.beforeEach((to, from, next) => {
    // All routes require auth, except for login
    if (to.matched.some(record => record.meta.requires_auth === false)) {
        // No auth needed for this page
        next()
    } else {
        // Check if logged in
        if (store.state.auth.isLoggedIn) {
            next();
        } else {
            next({
                name: 'Login'
            })
        }
    }
})


export default router
