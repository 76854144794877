import axios from 'axios'
import router from '../router'
import store from '../store'

let client = axios.create({
    baseURL: process.env.VUE_APP_API_URL.trim('/'),
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true,
});

client.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        // Redirect to login
        store.dispatch('logout')
        if ((router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'ResetPassword')) {
            router.push({'name': 'Login'})
        }
    }

    // rethrow the error
    return Promise.reject(error)
})

export default client;
