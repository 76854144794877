// initial state
import apiClient from "@/api";

const state = {
    articles: [],
}

// getters
const getters = {
    getOneArticle: (state) => articleId => {
        return state.articles.find(a => a.id === articleId)
    }
}

// actions
const actions = {
    getAllArticles({commit}) {
        return apiClient.get('/articles')
            .then(response => {
                commit('SET_ARTICLES', response.data)
            })
    },
    searchArticles({commit}, search) {
        return apiClient.get(`/articles/search/${search}`)
            .then(response => {
                commit('SET_ARTICLES', response.data)
            })
    },
}

// mutations
const mutations = {
    SET_ARTICLES(state, articles) {
        state.articles = articles
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
