<template>
    <v-app :class="{ 'login-view': ['Login', 'ForgotPassword', 'ResetPassword'].includes(this.$route.name) }">
        <Header v-if="showHeader"></Header>
        <NavigationDrawer v-if="showNavigation && !isMobile"></NavigationDrawer>
        <BottomNavigation v-else-if="showNavigation" />
        <v-main>
            <router-view/>
        </v-main>

        <Snackbar></Snackbar>
        <AppInstallDialog />
    </v-app>
</template>

<script>

import Header from "./components/layout/Header";
import Snackbar from "./components/layout/Snackbar";
import NavigationDrawer from "./components/layout/NavigationDrawer";
import BottomNavigation from "./components/layout/BottomNavigation";
import AppInstallDialog from "./components/layout/AppInstallDialog.vue";

export default {
    name: 'App',
    components: {NavigationDrawer, Snackbar, Header, BottomNavigation, AppInstallDialog},
    computed: {
        showNavigation () {
            return (typeof this.$route.meta.show_navigation === 'undefined') || this.$route.meta.show_navigation !== false;
        },
        showHeader () {
            return (typeof this.$route.meta.show_header === 'undefined') || this.$route.meta.show_header !== false;
        },
        isMobile() { return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) }
    }
};
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
