import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import auth from './modules/auth'
import materialOrder from './modules/materialOrder'
import workOrder from './modules/workOrder'
import workOrderComment from "@/store/modules/workOrderComment";
import comment from "@/store/modules/comment";
import timeRegistration from './modules/timeRegistration'
import article from "./modules/article";
import material from "./modules/material";
import project from "./modules/project";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
let plugins = []
if (debug) {
    plugins.push(createLogger)
}
plugins.push(createPersistedState({
    key: process.env.VUE_APP_VUEX_STORAGE_NAME,
    paths: [
        'auth.isLoggedIn',
        'auth.user',
        'article.articles',
        'material.materials',
        'materialOrder.materialOrders',
        'timeRegistration.users',
        'timeRegistration.selectedUser',
        'timeRegistration.timeRegistrations',
        'timeRegistration.usersWithUnconfirmedTimeRegs',
        'timeRegistration.selectTypes',
        'workOrder.workOrders',
        'workOrder.workOrder',
        'workOrder.projectWorkOrders',
        'workOrderComment.dashboardComments',
        'comment.comments',
        'project.formattedProjects',
    ]
}))


export default new Vuex.Store({
    modules: {
        app,
        auth,
        workOrder,
        workOrderComment,
        comment,
        timeRegistration,
        materialOrder,
        article,
        material,
        project
    },
    strict: debug,
    plugins
})
