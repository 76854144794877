import apiClient from "@/api";
// initial state
const state = {
    isLoggedIn: false,
    user: null,
}

// getters
const getters = {
    userIsAdmin: (state) => {
        if (null === state.user) {
            return false
        }
        return state.user.roles.find(role => role === 'ROLE_ADMIN') === "ROLE_ADMIN"
    },
    userCanOrder: (state) => {
        if (null === state.user) {
            return false
        }
        const admin = state.user.roles.includes('ROLE_ADMIN')
        const orderUser = state.user.roles.includes('ROLE_ORDER_USER')

        return admin || orderUser
    }
}

// actions
const actions = {
    login({commit}, { username, password, _remember_me }) {
        commit('SET_AUTH_STATE', { isLoggedIn: false})
        return apiClient.post('/login', JSON.stringify({ username, password, _remember_me }))
            .then(response => {
                if (response.status === 200) {
                    commit('SET_AUTH_STATE', {isLoggedIn: true, user: response.data })
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    logout({commit}) {
        // TODO: Ook cookie verwijderen
        commit('CLEAR_AUTH_STATE');
    },
    forgotPassword(_, { username }) {
        return apiClient.post('/forgot-password', { username })
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    validateResetPasswordToken(_, { token }) {
        return apiClient.post('/reset-password/'+token+'/validate')
            .then(response => {
                if (response.status === 200) {
                    return response.data.valid;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            }).catch(error => {
                console.error(error);
                return false;
            });
    },
    resetPassword(_, { token, password, birthday }) {
        return apiClient.post('/reset-password/'+token, { password, birthday })
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
}

// mutations
const mutations = {
    SET_AUTH_STATE(state, { isLoggedIn, user }) {
        state.isLoggedIn = isLoggedIn;
        state.user = user;
    },
    SET_USER(state, { user }) {
        state.user = user;
    },
    CLEAR_AUTH_STATE(state) {
        state.isLoggedIn = null;
        state.user = null;
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
