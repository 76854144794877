import apiClient from "@/api";

// initial state
const state = {
    projects: [],
    project: null,
    formattedProjects: null,
    loading: false,
}

// getters
const getters = {}

// actions
const actions = {
    getProjects({ commit }) {
        commit('LOADING_STARTED')
        return apiClient.get('/projects')
            .then(response => {
                commit('SET_FORMATTED_PROJECTS', response.data)
                commit('LOADING_FINISHED')
            })
    },
}

// mutations
const mutations = {
    SET_FORMATTED_PROJECTS(state, data) {
        state.formattedProjects = data.map(p => p = {
            value: p.id,
            name: `${p.name}_${p.projectNo}`,
        })
    },
    LOADING_STARTED(state) {
        state.loading = true
    },
    LOADING_FINISHED(state) {
        state.loading = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
