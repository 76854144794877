import apiClient from "@/api";

// initial state
const state = {
    workOrders: [],
    workOrder: null,
    projectWorkOrders: [],
    loading: false,
}

// getters
const getters = {
    getDashboardWorkOrders(state) {
        return state.workOrders.slice(0, 10)
    },
}

// actions
const actions = {
    getWorkOrders({ commit }) {
        commit('LOADING_STARTED')
        return apiClient.get('/workorders')
            .then(response => {
                commit('SET_WORK_ORDERS', response.data)
                commit('LOADING_FINISHED')
            })
    },
    getWorkOrder({ commit }, { workOrderId }) {
        commit('LOADING_STARTED')
        return apiClient.get('/workorders/' + workOrderId)
            .then(response => {
                commit('SET_WORK_ORDER', response.data)
                commit('LOADING_FINISHED')
            })
    },
    saveWorkOrderRemarks(_, { workOrderId, remarks }) {
        return apiClient.post(`/workorders/${workOrderId}/edit-remarks`, {'remarks': remarks})
    },
    getProjectWorkOrder({ commit }, {userId}) {
        commit('LOADING_STARTED')
        return apiClient.get('/workorders/projects/'+userId)
            .then(response => {
                commit('SET_PROJECT_WORK_ORDERS', response.data)
                commit('LOADING_FINISHED')
            })
    },
    searchWorkOrders({ commit }, { searchTerm }) {
        commit('LOADING_STARTED')
        return apiClient.get('/workorders/search/'+searchTerm)
            .then(response => {
                commit('SET_WORK_ORDERS', response.data)
                commit('LOADING_FINISHED')
            })
    },
    signWorkOrder({ dispatch }, { id, signatureData}) {
        return apiClient.post(`/workorders/${id}/signature`, {signature: signatureData})
            .then(response => {
                dispatch(`getWorkOrder`, {workOrderId: response.data.id})
                dispatch('showSnackbar', {text:'Handtekening opgeslagen'})
            })
            .catch(() => {
                dispatch('showSnackbar', {text:'Fout bij opslaan', color: 'red darken-2'})
            })
    }
}

// mutations
const mutations = {
    SET_WORK_ORDERS(state, workOrders) {
        state.workOrders = workOrders
    },
    SET_WORK_ORDER(state, workOrder) {
        workOrder.timeRegistrations.forEach((time => {
            time.workOrder = workOrder.id
            time.projectWorkOrder = `${workOrder.project.name} / ${workOrder.description}`
        }))

        state.workOrder = workOrder
    },
    SET_PROJECT_WORK_ORDERS(state, data) {
        state.projectWorkOrders = data.map(w => w = {
            value: w.id,
            name: `${w.project.name}_${w.voucherNumber}`,
        })
    },
    LOADING_STARTED(state) {
        state.loading = true
    },
    LOADING_FINISHED(state) {
        state.loading = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
