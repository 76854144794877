// initial state
import apiClient from "@/api";

const state = {
    materialOrders: [],
    loading: false,
}

// getters
const getters = {
    getDashboardMaterialOrders(state) {
        return state.materialOrders.slice(0, 10)
    },
    getNumberOfUnconfirmedOrders(state) {
        return state.materialOrders.filter(order => order.confirmed === null).length
    },
}

// actions
const actions = {
    getMaterialOrders({commit}) {
        return apiClient.get('/material-orders')
            .then(response => {
                commit('SET_MATERIAL_ORDERS', response.data)
            })
    },
    setOrderConfirmed({dispatch}, payload) {
        return apiClient.post(`/material-orders/${payload.id}/toggle-confirmed`)
            .then(response => {
                if (response.data.confirmed === null) {
                    dispatch('showSnackbar', {text: 'Bestelling reset'})
                } else {
                    dispatch('showSnackbar', {text: 'Bestelling bevestigd'})
                }
            })
    },
    newMaterialOrder({dispatch}, {materialOrder}) {
        return apiClient.post('/material-orders/new-material-order', materialOrder)
            .then(() => {
                dispatch('getMaterialOrders')
            })
    },
    deleteMaterialOrder(_, {materialOrderId}) {
        return apiClient.delete('/material-orders/'+materialOrderId+'/delete')
    },
}

// mutations
const mutations = {
    SET_MATERIAL_ORDERS(state, materialOrders) {
        state.materialOrders = materialOrders
    },
    LOADING_STARTED(state) {
        state.loading = true
    },
    LOADING_FINISHED(state) {
        state.loading = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
