<template>
    <v-bottom-navigation background-color="#4f4f4f" app grow dark :height="(isIos) ? 75 : 65" class="align-start" :class="{ 'pt-3': isIos, 'pt-4': !isIos }">
        <v-btn :to="{name: 'DashboardView'}" exact plain :ripple="false">
            <span>Dashboard</span>
            <v-icon>fal fa-home-alt</v-icon>
        </v-btn>

        <v-btn :to="{name: 'MaterialOrderListView'}" plain :ripple="false">
            <span>Bestellingen</span>
            <v-icon>fal fa-cart-shopping</v-icon>
        </v-btn>


        <v-btn :to="{name: 'WorkOrderListView'}" plain :ripple="false">
            <span>Werkbonnen</span>
            <v-icon>fal fa-list-check</v-icon>
        </v-btn>

        <v-btn :to="{name: 'TimeRegistrationView'}" plain :ripple="false">
            <span>Tijdsregistratie</span>
            <v-icon>fal fa-clock</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>

export default {
    name: "BottomNavigation",
    computed: {
        isIos() {
            var agent = window.navigator.userAgent,
                start = agent.indexOf('OS');

            if( ( agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1 ) && start > -1 ){
                return true;
            }

            return false;
        }
    }
}
</script>
