// initial state
import apiClient from "@/api";

const state = {
}

// getters
const getters = {
}

// actions
const actions = {
    addMaterial(_, {workOrderId, article}) {
        return apiClient.post(`/materials/${workOrderId}`, article)
    },
    removeMaterial(_, {workOrderId, materialId}) {
        return apiClient.delete(`/materials/${workOrderId}/${materialId}`)
    },
    updateQuantityMaterial(_, material) {
        return apiClient.put(`/materials/update-quantity/${material.id}/${material.quantity}`, )
    },
}

// mutations
const mutations = {
}

export default {
    state,
    getters,
    actions,
    mutations
}
