import apiClient from "@/api";

// initial state
const state = {
    users: [],
    selectedUser: null,
    timeRegistrations: [],
    usersWithUnconfirmedTimeRegs: [],
    timeTypes: ['een', 'twee'],
    loading: false,
}

// getters
const getters = {
    getTimesOfDay: (state) => date => {
        return state.timeRegistrations.filter(time => time.date === date)
    },
    getTimeTypes: (state) => isWorkOrder => {
        let items = (isWorkOrder) ? state.timeTypes.filter(t => t.isProjectBased === true) : state.timeTypes

        return items.map(t => {
            return {
                id: t.id,
                name: t.name
            }
        })
    },
    getTimeTypeNameById: (state) => id => {
        const timeType = state.timeTypes.filter(t => t.id === id)
        return (timeType.length > 0) ? timeType[0].name : ''
    },
    timeIsProjectBased: (state) => typeId => {
        let res = state.timeTypes.filter(t => t.id === typeId)
        if (res.length > 0) {
            return res[0].isProjectBased
        }

        return true
    },
    getUsernameById: (state) => id => {
        return state.users.find(u => u.id === id).name
    }
}

// actions
const actions = {
    getTimeRegistrationTypes({commit}) {
        return apiClient.get('/time-registrations-types')
            .then(response => {
                commit('SET_TIME_REGISTRATION_TYPES', response.data)
            })
    },
    getUsersWithTimeRegistrations({commit}) {
        return apiClient.get(`/time-registrations-users`)
            .then(response => {
                commit('SET_USERS', response.data)
            })
    },
    getTimeRegistrationsForWeekOfUser({commit}, {date, user}) {
        commit('LOADING_STARTED')
        return apiClient.get(`/time-registrations-of-week/${date}/${user}`)
            .then(response => {
                commit('SET_TIME_REGISTRATIONS', response.data)
                commit('LOADING_FINISHED')
            })
    },
    getUsersWithUnconfirmedTimeRegs({commit}) {
        return apiClient.get('/users/time-registrations-unconfirmed')
            .then(response => {
                commit('SET_USERS_WITH_UNCONFIRMED_TIMEREGS', response.data)
            })
    },
    saveToDatabase({commit, dispatch}, row) {
        commit('LOADING_STARTED')
        return apiClient.post('/time-registration/save', row)
            .then(() => {
                dispatch('showSnackbar', {text: 'Registratie opgeslagen'})
            })
            .catch(() => { dispatch('showSnackbar', {text: 'Fout bij opslaan', color: 'red darken-2'}) })
    },
    deleteTime({commit, dispatch}, {timeId}) {
        return apiClient.post(`/time-registration/delete/${timeId}`)
            .then(() => {
                commit('DELETE_TIME', timeId);
                dispatch('showSnackbar', {text: 'Registratie verwijderd'})
            })
    },
    submitTimeRegs({dispatch}, payload) {
        return apiClient.post(`/time-registrations/submit-week/${payload.date}`)
            .then(() => {
                dispatch('getTimeRegistrationsForWeekOfUser', payload)
                dispatch('showSnackbar', {text: 'Uren ingeleverd'})
            })
    },
}

// mutations
const mutations = {
    SET_TIME_REGISTRATION_TYPES(state, types) {
        state.timeTypes = types
    },
    SET_USERS(state, users) {
        state.users = users
    },
    SET_TIME_REGISTRATIONS(state, timeRegs) {
        state.timeRegistrations = timeRegs
    },
    SET_USERS_WITH_UNCONFIRMED_TIMEREGS(state, timeRegs) {
        state.usersWithUnconfirmedTimeRegs = timeRegs
    },
    DELETE_TIME(state, id) {
        state.timeRegistrations = state.timeRegistrations.filter(t => t.id !== id);
    },
    LOADING_STARTED(state) {
        state.loadingTimes = true
    },
    LOADING_FINISHED(state) {
        state.loadingTimes = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
