<template>
    <v-app-bar
        app
        color="primary"
        dark
        clipped-left
    >
        <v-app-bar-nav-icon v-if="!isMobile"
            @click.stop="$store.dispatch('toggleNavigationDrawer')"
        >
            <template v-slot:default v-if="drawer">
                <v-icon>fas fa-ellipsis-vertical</v-icon>
            </template>
        </v-app-bar-nav-icon>
        <v-app-bar-title><div class="d-flex align-center"><img src="../../assets/images/logo-sijbers-support.png" width="150" /></div></v-app-bar-title>
        <v-spacer></v-spacer>
        <v-menu v-if="$store.state.auth.isLoggedIn" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon size="16" class="mr-3">fas fa-user</v-icon> <small v-if="!isMobile">{{ username }}</small> <v-icon size="12" :class="{'ml-3' : !isMobile}">fas fa-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="logout" dense>
                    <v-list-item-title class="d-flex">Uitloggen <v-icon size="12" class="ml-2 ml-sm-auto">far fa-sign-out fa-fw</v-icon></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>

export default {
    name: "Header",
    computed: {
        username() {
            return (this.$store.state.auth.isLoggedIn) ? this.$store.state.auth.user.name : ''
        },
        isMobile() { return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) },
        drawer: {
            get() {
                return this.$store.state.app.drawer
            }
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({'name': 'Login'});
        }
    }
}
</script>

<style scoped>

</style>
