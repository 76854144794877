<template>
    <v-navigation-drawer
        app
        :mini-variant="drawer"
        permanent
        clipped
        dark
    >
        <v-list class="pa-0" two-line>
            <v-list-item link :to="{name: 'DashboardView'}" exact>
                <v-list-item-action>
                    <v-icon size="18">fal fa-home fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{name: 'MaterialOrderListView'}">
                <v-list-item-action>
                    <v-icon size="18">fal fa-shopping-cart fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Bestellingen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name: 'WorkOrderListView'}">
                <v-list-item-action>
                    <v-icon size="18">fal fa-list-check fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Werkbonnen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name: 'TimeRegistrationView'}">
                <v-list-item-action>
                    <v-icon size="18">fal fa-clock fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Tijdsregistratie</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: "NavigationDrawer",
    computed: {
        drawer: {
            get() {
                return this.$store.state.app.drawer
            },
            set(value) {
                this.$store.dispatch('toggleNavigationDrawer', value);
            }
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({'name': 'Login'});
        }
    }
}
</script>

<style scoped>

</style>
