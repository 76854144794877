<template>
    <v-dialog
        v-model="showDialog"
        v-if="isLoggedIn"
        persistent
        max-width="290"
    >
        <v-card v-if="isiOS">
            <v-card-title class="text-h5 primary--text">
                App installeren
            </v-card-title>
            <v-card-text>Deze website is ook als app te gebruiken. Klik in de browser Safari op het icoontje <img width="20" height="20" src="/img/ios-share-icon.png"> en vervolgen op <strong>"Zet op beginscherm"</strong> om deze toe te voegen aan uw apparaat.</v-card-text>
            <v-card-actions>
                <v-btn
                    color="grey"
                    text
                    @click="hideDialog(true)"
                >
                    Niet meer vragen
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title class="text-h5 primary--text">
                App installeren
            </v-card-title>
            <v-card-text>Deze website is ook als app te gebruiken. Klik op <strong>"Installeren"</strong> om deze toe te voegen aan uw apparaat.</v-card-text>
            <v-card-actions>
                <v-btn
                    color="grey"
                    text
                    @click="hideDialog(true)"
                >
                    Niet meer vragen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="promptInstall"
                >
                    Installeren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "AppInstallDialog",
    data: () => ({
        showDialog: false,
        cookieName: 'hide-install-dialog',
        isiOS: [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
        appInstallPrompt: null,
    }),
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
        }),
    },
    mounted () {
        if (this.getCookie(this.cookieName)) {
            // Never show the dialog.
            this.showDialog = false;
        } else if (this.isiOS) {
            this.showDialog = 'standalone' in window.navigator && window.navigator.standalone !== true;
        } else {
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.appInstallPrompt = e;
                this.showDialog = true;
            });
        }
    },
    methods: {
        hideDialog (closePermanent) {
            this.showDialog = false;

            if (closePermanent) {
                // Set a cookie so the dialog will never be opened again the following year.
                const date = new Date();
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
                let expires = "expires=" + date.toUTCString();
                document.cookie = this.cookieName + "=true;" + expires + ";path=/";
            }
        },
        getCookie (cookieName) {
            let name = cookieName + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        },
        promptInstall () {
            this.appInstallPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.appInstallPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    this.appInstallPrompt = null;
                    this.showDialog = false;
                }
            });
        },
    },
}
</script>
