import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (typescript)
import { nl } from 'vuetify/lib/locale'

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#f37f4e',
                secondary: '#7d7e82',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fal fa-chevron-down',
        },

    },
    lang: {
        locales: {nl},
        current: 'nl',
    },
});
