import apiClient from "@/api";

// initial state
const state = {
    comments: [],
}

// getters
const getters = {
    getOpenCommentsCount(state) {
        return state.comments.filter(comment => comment.answer === null).length
    }
}

// actions
const actions = {
    getLatestComments({ commit }) {
        return apiClient.get('/comments/latest')
            .then(response => {
                commit('SET_COMMENTS', response.data)
            })
    },
    sentComment({commit, dispatch}, { question }) {
        return apiClient.post(`/comments/sent-comment`, {question}).then(response => {
            commit('SET_COMMENT', response.data)
            dispatch('getLatestComments')
        })
    },
    sentCommentAnswer({commit, dispatch}, { commentId, answer }) {
        return apiClient.post(`/comments/sent-comment-answer/${commentId}`, {answer}).then(response => {
            commit('SET_COMMENT', response.data)
            dispatch('getLatestComments')
        })
    },
    deleteComment({commit, dispatch}, { commentId }) {
        return apiClient.delete(`/comments/${commentId}/delete`).then(response => {
            commit('SET_COMMENT', response.data)
            dispatch('getLatestComments')
        })
    },
}

// mutations
const mutations = {
    SET_COMMENTS(state, comments) {
        state.comments = comments
    },
    SET_COMMENT(state, comment) {
        state.comments.push(comment)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
