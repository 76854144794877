import apiClient from "@/api";

// initial state
const state = {
    dashboardComments: [],
}

// getters
const getters = {
    getOpenQuestionsCount(state) {
        return state.dashboardComments.filter(comment => comment.answer === null).length
    }
}

// actions
const actions = {
    getLatestWorkOrderComments({ commit }) {
        return apiClient.get('/workorder-comments/latest')
            .then(response => {
                commit('SET_DASHBOARD_COMMENTS', response.data)
            })
    },
    sentWorkOrderComment(_, { workOrderId, question }) {
        return apiClient.post(`/workorder-comments/sent-comment/${workOrderId}`, {question})
    },
    sentWorkOrderCommentAnswer(_, { workOrderCommentId, answer }) {
        return apiClient.post(`/workorder-comments/sent-comment-answer/${workOrderCommentId}`, {answer})
    },
    deleteWorkOrderComment(_, { workOrderCommentId }) {
        return apiClient.delete(`/workorder-comments/${workOrderCommentId}/delete`)
    },
}

// mutations
const mutations = {
    SET_DASHBOARD_COMMENTS(state, comments) {
        state.dashboardComments = comments
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
